import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MainSectionProjekty = () => {
    return (
        <section className="section-dark">
            <div className="banner">
                <div className="banner-img">
                    <StaticImage className="img" src="../../images/new-web/box-shadow.svg" alt="Box shadow" placeholder="blurred" />
                    <StaticImage className="img" src="../../images/new-web/projekty-banner-img.png" alt="Naša práca" placeholder="blurred" />
                </div>
                <h1>
                    Pomáhame vám rásť a <br className="brMobile" />inovovať, vďaka našim <br className="brMobile" />kvalitným online riešeniam
                </h1>
                <StaticImage className="arrow-down" src="../../images/new-web/arrow.svg" alt="Arrow" placeholder="blurred" />
            </div>       
        </section>
    )
}

export default MainSectionProjekty;

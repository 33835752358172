import React from "react"
import ContactUs from "../components/contactUs"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MainSectionProjekty from "../components/projekty/mainSection"
import ProjektyList from "../components/projekty/projektyList"
import "../styles/projekty.scss"

const ProjektyPage = () => (
  <Layout pageName="projekty-main">
    <SEO 
      title="Naša práca" 
      description="Našou prácou je vytvárať web stránky, aplikácie a informačné systémy presne podľa vašich predstáv. Pri tvorbe dbáme na všetky vaše požiadavky a ciele." 
    />
    <MainSectionProjekty/>
    <ProjektyList />
    <ContactUs 
      text={<>Zaujal vás niektorý z projektov? <br />Potrebujete niečo podobné, alebo máte svoju vlastnú predstavu?</>} 
      button="Porozprávajte nám o vašej predstave" 
    />
  </Layout>
)

export default ProjektyPage

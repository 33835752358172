import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-cool-inview";

const ProjektyListItem = (props) => {


    const { observe, inView } = useInView({
        unobserveOnEnter: true,
        threshold: 0.45
    });

    return (
        <div className={"project " + ((inView) ? "show" : "")} ref={observe}>
            <div>
                <Link to={"/referencia/" + props.url} ><img loading="lazy" src={props.bgImage} alt={props.client} /></Link>
            </div>
            <div>
                <p dangerouslySetInnerHTML={{__html: props.hashtag}}></p>
                <Link to={"/referencia/" + props.url} ><h3>{props.projectDescription}</h3></Link>
                <Link to={"/referencia/" + props.url} >Pozrieť projekt<StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" placeholder="blurred" /></Link>
            </div>
        </div>
    )
}

export default ProjektyListItem;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ProjektyListItem from "./projektyListItem";


const ProjektyList = () => {


    const data = useStaticQuery(graphql`
    query {
      allMysqlProjects(sort: {fields: case_study_rok, order: DESC}) {
        nodes {
          url
          real_url
          client
          hashtag
          project_description
        }
      },
        bgs: allFile(filter: { name: {eq: "bg-projects-2x"} , relativePath: {regex: "/projects/"}}) {
          nodes {
            name
            relativeDirectory
            publicURL
          }
        },
      }  
    `);


    const bgs = {};
    data.bgs.nodes.map((node) => {
      let str = node.relativeDirectory;
      let index = str.replace("projects/","");
      bgs[index] = node.publicURL;
    });

    return (
        <section class="projects">
            <h2>Ako sme pomohli našim klientom?</h2>
            {data.allMysqlProjects.nodes.map((node) => 
                <ProjektyListItem 
                    url={node.url}
                    client={node.client}
                    hashtag={node.hashtag}
                    projectDescription={node.project_description}
                    bgImage={bgs[node.url]}
                />
            )
            } 

        </section>
    )
}

export default ProjektyList;